.container {
    max-width: 100%;
    margin: auto;
    padding: 0 15px;
    position: relative;
}
@media screen and (min-width: 1280px) {
    .container {
        max-width: 1240px !important;
        margin: auto;
    }
}
@media screen and (min-width: 1340px) {
    .container {
        max-width: 1310px !important;
        margin: auto;
    }
}
.text-green-400{
    color:var(--color-mint-green) !important;
}
.text-blue-950{
    color:var(--color-main-blue) !important;
}
.d-flex{
    display: flex;
}
.align-center{
    align-items: center;
}
.justify-center{
    justify-content: center;
}
.btn-green{
    color:var(--color-mint-green);          
}
.btn-secondary{
    color:var(--color-primary);       
    padding: 8px 16px;   
    background-color: transparent;
    border:1px solid var(--color-primary);
    cursor: pointer;
    border-radius: 4px;
}
.read-more-btn{
    color:var(--color-primary);
    font-size: var(--font-size-section-text);
    font-weight: 500;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap:10px;
    &.mint-green{
        color:var(--color-mint-green);
    }
}

img, svg{
    max-width: 100%;
}
.guest-home-site-wrapper{
    *{
        font-family: "Satoshi";
       
    }
    h1{
        font-size: 64px;
        line-height: 80px;
    }
    h2{
        font-family: "Nohemi";
        font-weight: 400;
        font-size: 48px;
        line-height: 68px;
        color:var(--color-heading)
    }
    .text-white{
        color:var(--color-white)
    }
    .margin-auto-title{
        width: max-content;
        margin: auto;
    }
    @media  screen and (max-width: 991px) {
        h1{
            font-size: 52px;
            line-height: 64px;
        }
        h2{
            font-size: 38px;
            line-height: 48px;
            br{
                display: none;
            }
        }
    }
    @media  screen and (max-width: 680px) {
       .guesthom-sub-heading-wrapper{
            svg{
                width: 60px;
            }
       }
    }
    @media  screen and (max-width: 540px) {
        h1{
            font-size: 42px;
            line-height: 52px;
        }
        h2{
            font-size: 32px;
            line-height: 42px;
        }
    }
    @media  screen and (max-width: 420px) {
        h1{
            font-size: 32px;
            line-height: 42px;
        }
        h2{
            font-size: 24px;
            line-height: 34px;
        }
        .guesthom-sub-heading-wrapper{
            svg{
                width: 40px;
            }
       }
    }
    @media  screen and (max-width: 360px) {
        h1{
            font-size: 22px;
            line-height: 32px;
        }
        h2{
            font-size: 18px;
            line-height: 28px;
        }
    }
    .search-container {
        display: flex;
        align-items: center;
        width: 100%;
        height: 64px;
        border-radius: 12px;
        overflow: hidden;
        background-color: #ffffff;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      }
    
      .search-input {
        flex: 1;
        border: none;
        outline: none;
        padding: 0 20px;
        font-size: 16px;
        color: var(--color-text);
        max-width: calc(100% - 90px);
      }
    
      .search-input::placeholder {
        color: #b0b8c4;
      }
    
      .search-button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        background-color: var(--color-primary);
        color: #ffffff;
        border: none;
        padding: 7px 12px;
        font-size: 14px;
        cursor: pointer;
        transition: background-color 0.3s ease;
        position: relative;
        right: 12px;
        border-radius: 12px;
      }
    
      .search-button:hover {
        background-color: #002080;
      }
    
      .search-icon {
        font-size: 18px;
      }
}

