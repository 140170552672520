

.col-6{
    width: 49%;
}

.guesthom-client-section{
    padding: 52px 0;
    background-color: var(--color-primary);
    img{
        width: 8rem;
    }
    .client-heading-block{
        padding-bottom: 32px;
        width: max-content;
        margin: auto;
    }
}
.property-slider-section{
    background-color: var(--color-primary);
    padding: 80px 0;
    position: relative;
    .title-wrapper{
        padding-bottom: 60px;
    }
    .slider-controls-wrapper{
        background-color: #020C22;
        padding: 20px 30px;
        max-width: 366px;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        position: absolute;
        bottom: 0;
        right: 0;
        @media screen and (min-width:380px) {
            width: 366px;
        }
        .controls-row{
            display: flex;
            justify-content: space-between;
            color:var(--color-white);
            gap:64px;
            .prev-btn, .next-btn{
                cursor: pointer;
                &.disabled{
                    opacity: 0.5;
                    pointer-events: none;
                }
            }
            .rect-line{
                width: 32px;
                height: 2px;
                display: inline-block;
                vertical-align: middle;
                background-color: var(--color-white);
                padding: 0 13px;
                position: relative;
                top:-1px;
                margin: 0 13px;
            }
        }
    }
}
.hero-text-container{
    position: relative;
    z-index: 9;
    opacity: 1;
    max-width: 535px;
    margin: auto;
    top:130px;
    
    .hero-text-block{
        padding: 0 15px;
        h1{
            padding-bottom: 8px;
            font-family: "Nohemi";
            font-weight: 400;
        }
        p{
            padding-bottom: 28px;
        }
        .hero-btn{
            background-color: var(--color-primary);
            color:var(--color-white);
            padding: 10px 16px;
            border-radius: 8px;
        }
    }
}
.property-description{
    color:white;
    position: relative;
    background: #020C22;
    padding: 40px;
    border-radius: 8px;
    margin: auto;
    width: max-content;
    top: -79px;
    
    .d-flex{
        gap:60px;
        .block{
            text-align: left;
            // &:first-child{
            //     padding-right: 70px;
            // }
            .sub-heading{
                font-size: 16px;
                padding-bottom: 4px;
                color:#ECEEEF;
                opacity: 50%;
            }
            .desc{
                font-size: 20px;
                padding-bottom: 32px;
            }
        }
        .propert-name{
            font-size: 40px;
            line-height: 48px;
            max-width: 85%;
        }
    }
    @media  screen and (max-width: 991px) {
        width: 100%;
        top:0;
        padding: 24px;
        .d-flex{
            flex-wrap: wrap;
            gap:10px 20px;
          
            .propert-name{
                font-size: 22px;
                line-height: 32px;
               
            }
        }
        
    }
    @media  screen and (max-width: 680px) {
        .d-flex{
           margin-bottom: 36px;
           &:last-child{
            margin-bottom: 0;
           }
        }
    }
    @media  screen and (max-width: 480px) {
        .d-flex{
           .propert-name{
                max-width: 100%;
            }
        }
    }
}

.easy-rental-homes{
    padding: 100px 0;
    .rental-title{
        padding-bottom: 70px;
        @media screen and (max-width: 767px) {
            padding-bottom: 34px;
        }
    }
  
    .card-body{
        
        position: relative;
        justify-content: space-between;
        
        img{
            max-width: 240px;
            padding-bottom: 24px;
        }
        .right-block{
            background-image: url("../Assets/Images/home/bg-ractangle-dark.png");
            color: white;
            padding: 24px;
            position: absolute;
            top:27px;
            left: 55px;
            height: 240px;
            min-width: 100%;
            padding-left: 197px;
            z-index: -1;
            text-align: left;
            background-repeat: no-repeat;
            .card-title{
                font-size: var(--font-size-28);
                span{
                    font-size: var(--font-size-20);
                }
            }
            .sub-text{
                font-size:var(--font-size-card-sub-text);
                color:var(--color-mint-green);
                gap:4px;
                display: flex;
            }
            
            
            .amenitites{
                padding: 16px 0 8px 0;
                display: flex;
                gap:6px;
                flex-wrap: wrap;
                .amenity{
                    background-color: var(--color-primary);
                    font-size: 16px;
                    padding: 4px 12px;
                    display: flex;
                    align-items: center;
                    gap:4px;
                    border-radius: 8px;
                    color:var(--color-steel-faded);
                }
            }
            .card-text{
                font-size: 16px;
                padding: 0 0 8px 0;
                display: flex;
                gap: 8px;
                align-items: center;
            }
            &.light-color{
                background-image: url("../Assets/Images/home/bg-rectangle-light.png");
                color: #032850;
                .sub-text, .btn-green{
                    color:var(--color-green);
                }
            }
           
            
        }
        
        @media screen and (max-width:765px) {
            .image-block{
                img{
                    padding-bottom: 0;
                    max-width: 100%;
                }
            }
            .right-block{
                background-image: none;
                background-color:#000E2D;
                top:0;
                left: 0;
                padding: 24px;
                position: relative;
                border-radius: 0 0 8px 8px;
                height: auto;
                margin-top: -12px;
            }
           
        }
    }
    .slider-controls-wrapper{
        text-align: center;
        margin: auto;
        padding: 20px 30px;
        max-width: 366px;
        width: 366px;
        
       
        .controls-row{
            display: flex;
            justify-content: space-between;
            color:var(--color-text);
            .prev-btn, .next-btn{
                cursor: pointer;
                &.disabled{
                    opacity: 0.5;
                    pointer-events: none;
                }
            }
            .rect-line{
                width: 32px;
                height: 2px;
                display: inline-block;
                vertical-align: middle;
                background-color: var(--color-text);
                padding: 0 13px;
                position: relative;
                top:-1px;
                margin: 0 13px;
            }
        }
    }
    @media screen and (min-width:1360px) {
        .slick-slide {
            &:nth-child(even) {
              .card-body {
                .right-block {
                  background-image: url("../Assets/Images/home/bg-rectangle-light.png");
                  color: #032850;
          
                  .sub-text, 
                  .btn-green {
                    color: var(--color-green);
                  }
                  .card-text{
                    .icon{
                        svg{
                            path{
                                fill: var(--color-text);
                            }
                        }
                    }
                  }
                }
              }
            }
          }
    }
  
      
}
.why-guesthom-section{
    @media screen and (max-width:767px) {
        .border-dashed{
            padding: 0;
            margin: auto;
            border-color: transparent;
        }
    }
}
