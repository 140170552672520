.guesthom-about-us {
  *{
    font-weight: 500;
  }
  p {
    font-size: 16px;
    line-height: 24px;
    color: var(--color-steel);
  }
  .about-us-hero-section {
    padding: 60px 0;
    background-color: var(--color-mint-faded);
    .left-block {
      text-align: left;
      width: 60%;
    }
    .right-block {
      text-align: left;
      width: 40%;
      position: relative;
      padding-top: 150px;
      left: -97px;
    }
  }
  @media screen and (max-width: 1300px) {
    .about-us-hero-section {
      .right-block {
        padding-top: 50px;
        left: 0;
      }
    }
  }
  @media screen and (max-width: 991px) {
    .about-us-hero-section {
      .d-flex {
        flex-direction: column;
      }
      .left-block,
      .right-block {
        width: 100%;
      }
      .right-block {
        padding-top: 30px;
      }
    }
  }
  .we-follow {
    padding: 100px 0;
    .d-flex {
      justify-content: space-between;
      align-items: center;
    }
    .left-block {
      width: 46%;
      text-align: left;
      .icon-row {
        .value-wrapper {
          border-left: 1px dashed #b1b6bb;
          padding-left: 40px;
          .icon {
            margin-bottom: 32px;
          }
          &:nth-child(2) {
            position: relative;
            top: 80px;
          }
        }
      }
    }
    .right-block {
      width: 44%;
      text-align: left;
      h2 {
        padding-bottom: 20px;
      }
    }
  }
  @media screen and (max-width: 991px) {
    .we-follow {
      .d-flex {
        flex-direction: column-reverse;
      }
      .left-block,
      .right-block {
        width: 100%;
        .icon-row {
          flex-direction: row;
          flex-wrap: wrap;
          gap: 30px;
          justify-content: flex-start;
          .value-wrapper {
            border: 0;
            padding-left: 0;
          }
          .value-wrapper:nth-child(2) {
            top: 0;
          }
        }
      }
      .left-block {
        padding-top: 50px;
      }
    }
  }
  .investors {
    background-color: var(--color-primary);
    .d-flex {
      justify-content: space-between;
      .left-column {
        width: 40%;
        padding: 60px;
        text-align: left;
        h2,
        p {
          color: var(--color-white);
        }
        h2 {
          padding-bottom: 16px;
        }
      }
      .right-column {
        width: 55%;
      }
    }
  }
  @media screen and (max-width: 1290px) {
    .investors {
      .d-flex {
        align-items: center;
        .left-column {
          width: 50%;
        }
      }
    }
  }
  @media screen and (max-width: 991px) {
    .investors {
      .d-flex {
        flex-direction: column;
        .left-column {
          width: 100%;
          padding: 30px;
        }
        .right-column {
          width: 100%;
        }
      }
    }
  }
  .guesthom-experience {
    text-align: left;
    padding: 100px 0;
    h2 {
      padding-bottom: 48px;
    }
    .records-wrapper {
      display: flex;
      gap: 20px;
      .left-block {
        width: 36%;
        background-color: var(--color-pilot-faded);
        border-radius: 20px;
        padding: 40px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .money {
          font-size: 64px;
        }
        .track-record {
          font-size: 36px;
        }
      }
      .right-block {
        width: 62%;
        .numbers-row {
          flex-wrap: wrap;
          gap: 20px;
          .number-card {
            width: 36%;
            padding: 20px 40px;
            .number {
              color: var(--color-mint-green);
              font-size: 48px;
              line-height: 62px;
              padding: 0 0 20px 0;
            }
            p {
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1040px) {
    .guesthom-experience {
      .records-wrapper {
        flex-direction: column;
        .left-block {
          width: 100%;
          padding: 30px;
        }
        .right-block {
          width: 100%;
        }
      }
    }
  }
  @media screen and (max-width: 991px) {
    .guesthom-experience {
      .records-wrapper {
        .left-block {
          gap: 18px;
          .money {
            font-size: 40px;
          }
          .track-record {
            font-size: 28px;
          }
        }
        .right-block {
          .numbers-row .number-card {
            width: 46%;
          }
        }
      }
    }
  }
  @media screen and (max-width: 540px) {
    .guesthom-experience {
      .records-wrapper {
        .right-block {
          .numbers-row .number-card {
            width: 100%;
          }
        }
      }
    }
  }
  .leader-section {
    background-color: var(--color-mint-faded);
    padding: 48px 0 0;
    text-align: left;
    position: relative;
    margin-bottom: 100px;
    overflow: hidden;
    h2 {
      padding-bottom: 16px;
    }
    .d-flex {
      justify-content: space-between;
      .left-block {
        width: 35%;
      }
      .right-block {
        width: 45%;
        .img-block {
          position: relative;
          max-width: 428px;

          .img-vector {
            position: absolute;
            top: 0;
            left: -57px;
          }
          .name-section {
            padding: 24px;
            background-color: #ddefe3;
            text-align: center;
            h6 {
              font-size: 24px;
            }
          }
        }
      }
    }
    .img-bg-vector {
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0.2;
    }
  }
  @media screen and (max-width: 991px) {
    .leader-section {
      .d-flex {
        flex-direction: column;
        .left-block {
          width: 100%;
          padding-bottom: 50px;
        }
        .right-block {
          width: 100%;
          .img-block .img-vector {
            display: none;
          }
        }
      }
    }
  }
  .careers{
    padding-bottom: 100px;
    .title-wrapper{
        .guesthom-sub-heading-wrapper{
            justify-content: center;
        }
    }
    h2{
        padding-bottom: 40px;
    }
    .slider-wrapper{
        text-align: left;
        .career-card{
            padding: 20px;
            background-color: #F8F9FB;
            margin-right: 16px;
            max-width: 298px;
            display: block !important;
            margin: auto;
            .career-icon{
                width: 60px;
                height: 60px;
            }
            .career-details{
                padding-top: 56px;
                h6{
                    font-size: 24px;
                    padding-bottom: 4px;
                }
                .position{
                    padding-bottom: 28px;
                }
            }
        }
    }
    .slider-controls-wrapper{
        text-align: center;
        margin: auto;
        padding: 20px 30px;
        max-width: 100%;
        width: 366px;
        margin-top: 48px;
       
        .controls-row{
            display: flex;
            justify-content: space-between;
            color:var(--color-text);
            .prev-btn, .next-btn{
                cursor: pointer;
                &.disabled{
                    opacity: 0.5;
                    pointer-events: none;
                }
            }
            .rect-line{
                width: 32px;
                height: 2px;
                display: inline-block;
                vertical-align: middle;
                background-color: var(--color-text);
                padding: 0 13px;
                position: relative;
                top:-1px;
                margin: 0 13px;
            }
        }
    }
  }
}
