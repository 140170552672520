@font-face {
    font-family: 'Nohemi';
    src: url('../../public/fonts/Nohemi-Thin.woff') format('woff');
    font-weight: 100;
  }
  
  @font-face {
    font-family: 'Nohemi';
    src: url('../../public/fonts/Nohemi-Light.woff') format('woff');
    font-weight: 300;
  }
  
  @font-face {
    font-family: 'Nohemi';
    src: url('../../public/fonts/Nohemi-Regular.woff') format('woff');
    font-weight: 400;
  }
  
  @font-face {
    font-family: 'Nohemi';
    src: url('../../public/fonts/Nohemi-Medium.woff') format('woff');
    font-weight: 500;
  }
  
  @font-face {
    font-family: 'Nohemi';
    src: url('../../public/fonts/Nohemi-SemiBold.woff') format('woff');
    font-weight: 600;
  }

  /* Satoshi fonts */
  @font-face {
    font-family: 'Satoshi';
    src: url('../../public/fonts/satoshi/Satoshi-Black.woff') format('woff');
    font-weight: 700;
  }
  
  @font-face {
    font-family: 'Satoshi';
    src: url('../../public/fonts/satoshi/Satoshi-Bold.woff') format('woff');
    font-weight: 600;
  }
  
  @font-face {
    font-family: 'Satoshi';
    src: url('../../public/fonts/satoshi/Satoshi-Light.woff') format('woff');
    font-weight: 300;
  }
  
  @font-face {
    font-family: 'Satoshi';
    src: url('../../public/fonts/satoshi/Satoshi-Medium.woff') format('woff');
    font-weight: 500;
  }
  
  @font-face {
    font-family: 'Satoshi';
    src: url('../../public/fonts/satoshi/Satoshi-Regular.woff') format('woff');
    font-weight: 400;
  }
:root {
  --font-size-14: 14px;
  --font-size-28: 28px;
  --font-size-20: 20px;
  --font-size-section-text: 16px;
  --font-size-section-title: 48px;
  --font-size-card-title: 24px;
  --font-body: 16px;
  --font-size-card-sub-text: 14px;
  --font-size-card-body: 16px;
}