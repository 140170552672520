/* Custom styles for Ant Design components in HeroSection */

.property-info .ant-select {
  font-size: 14px;
  color: var(--color-primary);
  line-height: 24px;
  font-weight: 500;
}

.property-info .ant-select-selection-item {
  padding: 0 !important;
}

.property-info .ant-select-selector {
  padding: 0 !important;
  height: auto !important;
}

/* Search button - restored to original color */
.property-info .search-button.ant-btn {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  border-radius: 8px;
  padding: 7px 12px;
  font-weight: 500;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: #ffffff;
  border: none;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.property-info .search-button.ant-btn:hover {
  background-color: #002080;
}

.property-info .search-button.ant-btn svg {
  margin-right: 4px;
}

/* Improved dropdown styling */
.property-info .ant-select-dropdown {
  border-radius: 12px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  padding: 8px 0;
}

.property-info .ant-select-dropdown .ant-select-item {
  padding: 12px 16px;
  font-size: 14px;
  border-radius: 0;
  transition: background-color 0.2s ease;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Match the exact blue background from screenshot */
.property-info .ant-select-dropdown .ant-select-item-option-selected {
  background-color: #eaf6ff;
  font-weight: 500;
}

.property-info .ant-select-dropdown .ant-select-item-option-active {
  background-color: #f5f9ff;
}

/* Style for the checkmark icon to match blue color in screenshot */
.property-info .ant-select-item-option-state {
  color: #1677ff;
  font-size: 16px;
  font-weight: bold;
}

/* Add a blue checkmark for selected items */
.property-info .ant-select-item-option-selected .ant-select-item-option-state {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Make the dropdown items spaced better */
.property-info .ant-select-dropdown .ant-select-item + .ant-select-item {
  margin-top: 2px;
}

/* Multiple selection tag styling */
.property-info .ant-select-multiple .ant-select-selection-item {
  background: #f7f7f7;
  border-radius: 6px;
  border: 1px solid #eee;
  padding: 2px 8px !important;
  margin: 2px;
}

/* Ensure multiple select doesn't break the layout */
.property-info .ant-select-multiple .ant-select-selection-overflow {
  display: flex;
  flex-wrap: nowrap;
}

/* Style for the selected items display area */
.property-info .ant-select-multiple .ant-select-selector {
  padding: 0 8px !important;
  background-color: #f7f7f7;
  border-radius: 8px;
}

/* Tag count (+2) styling */
.property-info .ant-select-selection-item-content {
  margin-right: 4px;
}

/* Style the "+2 ..." text */
.property-info .ant-select-selection-overflow-item-suffix {
  color: #666;
  background: transparent;
  font-size: 16px;
  margin-left: 8px;
}

/* Improve the tag appearance */
.property-info .ant-select-multiple .ant-select-selection-search {
  margin-left: 4px;
}

/* Round corners of the dropdown */
.property-info .ant-select-dropdown {
  overflow: hidden;
}

/* Style the first and last items to have rounded corners */
.property-info .ant-select-dropdown .ant-select-item:first-child {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.property-info .ant-select-dropdown .ant-select-item:last-child {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

/* Remove arrow for cleaner look */
.property-info .ant-select-multiple.ant-select-show-arrow .ant-select-arrow,
.property-info .ant-select-single.ant-select-show-arrow .ant-select-arrow {
  display: none;
}

/* Styling for toggle switches */
.toggle-wrapper {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-right: 20px;
}

.toggle-label {
  font-size: 14px;
  font-weight: 500;
  color: var(--color-primary);
}

/* Style for the Ant Design Switch */
.ant-switch {
  background-color: rgba(0, 0, 0, 0.15);
  min-width: 44px;
}

.ant-switch.ant-switch-checked {
  background-color: var(--color-primary);
}

.ant-switch .ant-switch-handle {
  width: 18px;
  height: 18px;
  top: 2px;
}

.ant-switch.ant-switch-checked .ant-switch-handle {
  inset-inline-start: calc(100% - 18px - 2px);
}

/* Search results area styling */
.search-results-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
}

.search-results-area h6 {
  font-size: 16px;
  font-weight: 500;
  color: var(--color-primary);
  margin: 0;
}

.search-results-area .actions-area {
  display: flex;
  gap: 20px;
  align-items: center;
}

/* Style for the filter and sort buttons */
.action-btns {
  display: flex;
  gap: 8px;
}

.btn-primary {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 6px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  color: var(--color-primary);
}

.btn-primary:hover {
  background-color: #f1f3f5;
}

.btn-icon {
  display: flex;
  align-items: center;
}

/* Map view toggle visibility control */
.map-view-content {
  display: none;
}

.map-view-active .map-view-content {
  display: block;
}

.list-view-content {
  display: block;
}

.map-view-active .list-view-content {
  display: none;
}

/* Subtle spacing improvements */
.guesthom-aletrnate-hero-section .top-area {
  padding-bottom: 30px; /* Moderate spacing between heading and search bar */
}

/* Search fields spacing */
.property-info .d-flex {
  gap: 16px; /* Subtle spacing between search fields */
}

/* Make search button align properly */
.property-info .search-block {
  display: flex;
  align-items: flex-end;
  padding-bottom: 5px;
}

/* Popular localities spacing */
.popular-localities {
  margin-top: 16px;
  margin-bottom: 16px;
}

.popular-localities .popular-title {
  margin-right: 10px;
  font-weight: 500;
}

.popular-localities .badge {
  margin-right: 10px;
  padding: 6px 12px;
  border-radius: 20px;
  background-color: #f5f5f5;
  font-size: 14px;
  cursor: pointer;
}

/* Filter options spacing */
.search-results-area {
  padding: 20px 0;
}

.search-results-area .actions-area {
  display: flex;
  align-items: center;
  gap: 15px;
}

.toggle-wrapper {
  margin-right: 10px;
}

/* Property card spacing */
.home-row {
  gap: 20px;
  margin-top: 15px;
}

.card .image-block {
  margin-bottom: 15px;
  overflow: hidden;
  border-radius: 8px;
}

.card .home-card-row {
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.card .amenitites {
  display: flex;
  gap: 8px;
  margin-bottom: 10px;
}

.card .card-text {
  margin-bottom: 8px;
}

.card .green-text {
  color: #4CAF50;
  font-size: 14px;
  display: block;
  margin-top: 5px;
}
