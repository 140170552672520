:root {
    --color-primary: #032850;
    --color-main-blue: #032850;
    --color-pilot-blue: #032850;
    --color-pilot-faded: #E6E9ED;
    --color-steel: #636E77;
    --color-steel-faded: #ECEEEF;
    --color-green: #1B7E3B;
    --color-mint-green: #8EC9A1;
    --color-divider: #EFF0F3;
    --color-black: #000000;
    --color-white: #FFFFFF;
    --color-text:#22252E;
    --color-mint-faded:#F4FAF6;
    --color-heading:#191A23;
  }