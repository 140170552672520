.guesthom-faq {
 .guesthom-hero-section {
    background: linear-gradient(
      45deg,
      rgba(218, 255, 230, 0.3) 0%,
      rgba(200, 226, 255, 0.3) 100%
    );
    padding: 60px 0 10px;
    .search-container {
      position: relative;
      top: 40px;
      max-width: 760px;
      margin: auto;
    }
  }
  .question {
    padding: 92px 0 80px;
  }
  .rental-buyers,
  .common-faq {
    padding: 80px 0;
    background-color: var(--color-mint-faded);
  }
  .bg-white {
    background-color: var(--color-white);
  }
  h2 {
    padding-bottom: 48px;
  }
  .q-row {
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 20px;
    display: flex;
    max-width: 80%;
    margin: auto;
    .question-card {
      background-color: var(--color-white);
      padding: 16px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.05);
      min-width: 300px;
      max-width: 100%;
      text-align: left;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 500;
    }
  }
  .doubts {
    padding: 18px 0;
    background-color: rgba(142, 201, 161, 0.4);
    span {
      font-weight: 500;
      text-decoration: underline;
    }
  }
  @media screen and (max-width: 991px) {
    .q-row {
      width: 100%;
    }
  }
  @media screen and (max-width: 360px) {
    .q-row {
      a {
        width: 100%;
      }
      .question-card {
        min-width: 100%;
      }
    }
  }
}
.guesthom-faq-detail {
  .faq-detail-hero-section {
    text-align: left;
    padding: 60px 0 83px;
    background: linear-gradient(
      45deg,
      rgba(218, 255, 230, 0.3) 0%,
      rgba(200, 226, 255, 0.3) 100%
    );
    .d-flex {
      .left-block {
        width: 32%;
        .back-to-faq {
          padding-bottom: 40px;
          color:var(--color-steel);
          display: flex;
          align-items: center;
          gap:12px;
        }
        
        h4 {
          font-size: 28px;
          line-height: 40px;
          padding-bottom: 8px;
          font-family: "Nohemi";
          font-weight: 400;
        }
        p {
          color: var(--color-steel);
          padding-bottom: 8px;
        }
      }
      .right-block {
        width: 62%;
        .accordion {
          .accordion-item {
            background-color: var(--color-white);
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1),
              0 1px 3px rgba(0, 0, 0, 0.05);
            border-radius: 5px;
            margin-bottom: 14px;
            padding: 24px;
            cursor: pointer;
            .accordion-header {
              font-size: 24px;
              line-height: 32px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-weight: 500;
              color:var(--color-primary);
              &.active {
                padding-bottom: 18px;
                svg {
                  transform: rotate(90deg);
                  transition: all 0.2s;
                }
              }
            }
            .accordion-content {
              color: var(--color-steel);
              font-weight: 500;
            }
          }
        }
      }
    }
  }
  .faq-accordions {
    background: var(--color-white);
    background-color: var(--color-white);
    padding-bottom: 327px;
    position: relative;
    &.faq-detail-hero-section .d-flex .right-block {
      position: absolute;
      top: -288px;
      right: 0;
    }
  }
  @media screen and (max-width: 991px) {
    .faq-detail-hero-section .d-flex .right-block .accordion .accordion-item .accordion-header{
      font-size: 18px;
      line-height: 30px;
    }
    .faq-detail-hero-section .d-flex {
      .left-block {
        width: 100%;
      }
      .right-block {
        width: 100%;
        display: none;
      }
    }
    .faq-accordions.faq-detail-hero-section {
      padding-bottom: 83px;
      .d-flex {
        .left-block {
          display: none;
        }
        .right-block {
          display: block;
          top: 0;
          position: relative;
        }
      }
    }
  }
}
