.guesthom-range-slider-container {
    width: 100%;
  
    .range-slider {
      -webkit-appearance: none;
      width: 100%;
      height: 6px; /* Track height */
      background: transparent; /* Transparent background */
      position: relative; /* Required for pseudo-elements */
      cursor: pointer;
    }
  
    .range-slider::-webkit-slider-runnable-track {
      width: 100%;
      height: 6px; /* Track height */
      background: rgba(230, 233, 237, 1); /* Default track color */
      border-radius: 4px; /* Optional: rounded corners */
    }
  
    .range-slider::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 20px; /* Thumb width */
      height: 20px; /* Thumb height */
      border-radius: 50%; /* Make thumb circular */
      background: #fff; /* Thumb background color */
      border: 4px solid var(--color-mint-green); /* Thumb border */
      cursor: pointer;
      position: relative;
      z-index: 2;
      margin-top: -6px; /* Center the thumb over the track */
    }
  
    .range-slider::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 6px; /* Track height */
      background: var(--color-mint-green); /* Filled portion color */
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      z-index: 1;
      width: calc((var(--value) - var(--min)) * 100% / (var(--max) - var(--min)));
      max-width: 100%;
    }
  
    .range-slider::-webkit-slider-runnable-track::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      height: 6px; /* Track height */
      background: #ddd; /* Unfilled portion color */
      border-radius: 4px;
      z-index: 0;
      width: calc(
        100% - (var(--value) - var(--min)) * 100% / (var(--max) - var(--min))
      );
    }
  }
  