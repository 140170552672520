.mortgage-calculator {
    font-family: Arial, sans-serif;
    max-width: 100%;
    margin: 0 auto 48px;
    background: var(--color-mint-faded);
    padding: 20px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .calculator-container {
      display: flex;
      gap: 20px;
    }
    
    .inputs{
      width: 70%;
      p{
        padding-bottom: 20px;
      }
      .input-group {
        label{
          color:var(--color-primary);
          padding-bottom: 8px;
        }
        .input-slider{
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap:28px;
          input{
            width: 100%;
          }
          input[type=number]{
            padding: 12px 16px;
          }
        }
      }
      
    }
    .results{
      width: 35%;
      h3{
        padding: 20px 0 0;
        font-size: 28px;
        color:var(--color-primary)
      }
      p{
        color:var(--color-steel);
        font-size: 16px;
        font-weight: 500;
        padding-bottom: 25px;
      }
    }
    .inputs h2 {
      margin-bottom: 10px;
      color: #333;
    }
    
    .input-group {
      margin-bottom: 15px;
    }
    
    .input-group label {
      display: block;
      font-size: 14px;
      color: #555;
      margin-bottom: 5px;
    }
    
    .slider-container {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    
    .slider-container input[type="range"] {
      flex: 1;
      cursor: pointer;
    }
    
    .slider-container .value {
      font-size: 14px;
      color: #333;
    }
    
    .results {
      text-align: center;
      border-left: 1px solid var(--color-divider);
      padding: 20px;
      border-radius: 8px;
      .home-pie-chart{
        max-width: 150px;
        margin: auto;
      }
    }
    
  
    
    .results ul {
      list-style: disc;
      padding: 0;
      margin: 15px 0;
      color: #555;
      text-align: left;
      padding-left: 15px;
    }
    
    .results ul li {
      margin: 5px 0;
    }
    
    .results canvas {
      margin-top: 20px;
    }
    
  }