.guesthom-blogs-home {
  .featured-blogs {
    padding: 60px 0 72px;
    .title-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h2 {
        padding-bottom: 40px;
      }
    }
    .grid-graphic {
      position: absolute;
      top: 72px;
      right: 0;
    }
    .featured-blogs-row {
      gap: 28px;
    }
    .left-block {
      width: 43%;
      .blog-card {
        text-align: left;
        .img-block {
          padding-bottom: 28px;
          img {
            object-fit: none;
            object-position: center;
            height: 351px;
            width: 540px;
            border-radius: 8px;
          }
        }
        .content-block {
          padding-bottom: 16px;
          font-weight: 500;
          .date {
            font-size: 14px;
            line-height: 20px;
            color: #636e77;
            padding-bottom: 12px;
          }
          .title {
            font-size: 32px;
            line-height: 48px;
            padding-bottom: 4px;
            color: var(--color-primary);
            font-family: "Nohemi";
            font-weight: 400;
          }
          .description {
            font-size: 16px;
            line-height: 24px;
            color: var(--color-steel);
          }
        }
        .action-btn {
        }
      }
    }
    .right-block {
      width: 53%;
      .blog-card {
        text-align: left;
        .card-row {
          gap: 24px;
          margin-bottom: 28px;

          .img-block {
            img {
              object-fit: none;
              object-position: center;
              height: 201px;
              width: 272px;
              border-radius: 6px;
              min-width: 272px;
            }
          }

          .content-block {
            text-align: left;
            font-weight: 500;
            .date {
              font-size: 14px;
              line-height: 20px;
              color: var(--color-steel);
              padding-bottom: 12px;
            }
            .title {
              font-size: 24px;
              line-height: 36px;
              padding-bottom: 4px;
              color: var(--color-primary);
              font-family: "Nohemi";
              font-weight: 400;
            }
            .description {
              font-size: 16px;
              line-height: 24px;
              color: var(--color-steel);
              padding-bottom: 16px;
            }
          }
        }
      }
    }
  }
  .search-container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 64px;
    border-radius: 12px;
    overflow: hidden;
    background-color: #ffffff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .search-input {
    flex: 1;
    border: none;
    outline: none;
    padding: 0 20px;
    font-size: 16px;
    color: var(--color-text);
    max-width: calc(100% - 90px);
  }

  .search-input::placeholder {
    color: #b0b8c4;
  }

  .search-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    background-color: var(--color-primary);
    color: #ffffff;
    border: none;
    padding: 7px 12px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    position: relative;
    right: 12px;
    border-radius: 12px;
  }

  .search-button:hover {
    background-color: #002080;
  }

  .search-icon {
    font-size: 18px;
  }

  @media screen and (max-width: 1300px) {
    .featured-blogs .right-block .blog-card {
      .card-row {
        .content-block {
          .title {
            font-size: 16px;
            line-height: 26px;
          }
          .description {
            font-size: 14px;
            line-height: 22px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 991px) {
    .featured-blogs .featured-blogs-row {
      flex-direction: column;
      .left-block {
        width: 100%;
        .blog-card {
          .content-block .title {
            font-size: 24px;
            line-height: 34px;
          }
          .img-block img {
            height: auto;
          }
        }
      }
      .right-block {
        width: 100%;
      }
    }
    .recent-blogs .title-wrapper .outer-div {
      flex-direction: column;
      gap: 16px;
      text-align: left;

      .left-block {
        width: 100%;
      }
      .right-block {
        width: 100%;
      }
    }
    .blog-cards-wrapper {
      .blog-row {
        flex-direction: column;
        .blog-card {
          width: 100%;
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 540px) {
    .featured-blogs .right-block .blog-card .card-row {
      flex-direction: column;
      .img-block {
        width: 100%;
        img {
          width: 100%;
        }
      }
    }
  }
  @media screen and (max-width: 420px) {
  }
  @media screen and (max-width: 380px) {
  }
}

.guesthom-blogs-detail {
  .blog-details-section {
    padding: 60px 0 72px;
    .blog-detail-outer-row {
      gap: 60px;
      .left-block {
        width: 760px;
        max-width: 100%;
        text-align: left;
        .read-detail {
          display: flex;
          align-items: center;
          gap: 12px;
          margin-bottom: 30px;
        }
        h1 {
          font-family: "Nohemi";
          font-weight: 400;
          font-size: 48px;
          line-height: 68px;
          color: var(--color-heading);
          padding-bottom: 28px;
        }
        .blog-img-block {
          margin-bottom: 28px;
          border-radius: 8px;
          overflow: hidden;
          height: 494px;
          width: 100%;
          img {
            object-position: center;
            height: 100%;
            width: 100%;
            border-radius: 8px;
          }
        }
        .blog-description {
          h2 {
            font-size: 28px;
            line-height: 40px;
            font-family: "Nohemi";
            font-weight: 400;
            padding-bottom: 8px;
          }
          p {
            font-size: 20px;
            line-height: 32px;
            color: #032850;
            padding-bottom: 24px;
          }
          ul {
            padding: 0 0 0 20px;
            margin: 0 0 24px 0;
            list-style: disc;
            li {
              font-size: 20px;
              line-height: 32px;
              color: #032850;
              padding-bottom: 8px;
            }
          }
          h3 {
            font-size: 20px;
            font-weight: 500;
            padding-bottom: 12px;
          }
          blockquote {
            padding: 20px;
            border-left: 2px solid var(--color-mint-green);
            margin-bottom: 24px;
            background-color: var(--color-mint-faded);
            font-style: italic;
            p {
              padding-bottom: 0;
            }
          }
          img {
            border-radius: 12px;
            height: auto;
            width: 100%;
            margin-bottom: 24px;
          }
        }
        .author-bio {
          margin-top: 40px;
          .bio-row {
            padding-bottom: 24px;
            border-bottom: 1px solid #ebebf0;
          }
          .post-row {
            padding-top: 24px;
            div {
              display: flex;
              align-items: center;
              gap: 4px;
            }
          }
          .d-flex {
            justify-content: space-between;
          }
        }
        .social-shares {
          svg {
            margin-right: 32px;
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
      .right-block {
        width: calc(100% - 820px);
        max-width: 340px;
        text-align: left;
        .small-title {
          margin-top: 32px;
        }

        .related-article {
          margin-top: 48px;
          img {
            height: 208px;
            width: 100%;
            object-fit: none;
            object-position: center;
            clip-path: polygon(78% 0, 100% 24%, 100% 100%, 0 100%, 0 0);
          }
          .content-area {
            padding: 24px;
            background-color: var(--color-mint-faded);
            .cta {
              padding-top: 28px;
              display: flex;
              align-items: center;
              gap: 8px;
            }
          }
        }
        .social-shares {
          svg {
            margin-right: 32px;
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
    .author-details {
      display: flex;
      align-items: center;
    }
    .author-img {
      margin-right: 8px;
      img {
        width: 36px;
        height: 36px;
        border-radius: 100%;
      }
    }
    .small-title {
      font-size: 14px;
      line-height: 20px;
      color: var(--color-steel);
      padding-bottom: 12px;
    }
  }
  @media screen and (max-width: 1300px) {
    .blog-details-section {
      .blog-detail-outer-row {
        .left-block {
          width: 740px;
        }
        .right-block {
          width: auto;
        }
      }
    }
  }
  @media screen and (max-width: 991px) {
    .blog-details-section {
      .blog-detail-outer-row {
        flex-direction: column;
        .left-block {
          width: 100%;
        }
        .right-block {
          width: 100%;
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    .blog-details-section {
      .blog-detail-outer-row {
        
        .left-block {
          h1{
            font-size: 28px;
            line-height: 40px;
          }
          .blog-description{
            h2{
              font-size: 24px;
              line-height: 36px;
            }
          }
          
          .blog-img-block{
            height: auto;
            img{
              height: auto;
            }
          }
        }
        
      }
    }
  }
}
